<template>
    <div class="d-none d-md-block">
        <b-progress 
            :max="max" 
            :class="mainClass" 
            :animated="animated" 
            :height="height"
        >
            <b-progress-bar 
                :value="used"
                :label="usedLabel"
                :variant="variant" 
            />
            <b-progress-bar 
                :value="unUsed"
                :label="unUsedLabel"
                :variant="'success'" 
            />
        </b-progress>
    </div>
</template>

<script>
export default {
    name: "CustomProgressBar",
    props: {
        max: {
            type: Number,
            default: () => 100
        },
        mainClass: {
            type: Array|String|Object,
            default: () => 'mt-1',
        },
        animated: {
            type: Boolean,
            default: () => true
        },
        variant: {
            type: String,
            default: () => 'danger'
        },
        height: {
            type: String,
            default: () => '1.5rem'
        },
        value: {
            type: Number,
            default: () => 0
        },
    },
    computed: {
        used: {
            get(){
                return this.value
            }
        },
        unUsed: {
            get(){
                return this.max - this.value
            }
        },
        usedLabel: {
            get(){
                if(this.max <= 0) return "0"
                const percentage = ((this.value/this.max) * 100).toFixed(0)
                if(percentage < 20) return `${percentage}%`
                return `${this.$t('Used')}: ${percentage}%`
            }
        },
        unUsedLabel: {
            get(){
                if(this.max <= 0) return "0"
                const percentage = ((this.unUsed/this.max) * 100).toFixed(0)
                if(percentage < 20) return `${percentage}%`
                return `${this.$t('Remaining')}: ${percentage}%`
            }
        }
    }
}
</script>