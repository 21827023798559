<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="4" class="d-flex align-items-center">
            <b-card class='d-none d-md-block w-100 card-designs'>
              <b-row>
                <b-col cols="12">
                  <b-row class="d-flex justify-content-between align-items-center h-md-25vh">
                    <b-col cols="4">
                      <b-avatar size="48" class="avatar-border-2 box-shadow-1 d-block d-md-none" variant="primary"
                        :src="userAvatar" />
                      <b-img fluid class="d-none d-md-inline-block rounded" :src="userAvatar" />
                    </b-col>
                    <b-col cols="8" class="text-light">
                      <p class="mb-1 fs-16px fs-md-2rem">Hi, {{ userData.first_name }}</p>
                    </b-col>
                  </b-row>
                  <p class="text-white">{{ $t('Transform your network into net-worth! Earn juicy commissions when you invite friends and others to subscribe!') }}</p>
                </b-col>
                <b-col cols="12" class="mt-1">
                  <b-button variant="primary" size="sm" class="text-light mr-1 mt-1 mt-md-0"
                    :to="{ name: 'user-profile', query: { tab: 'subscription' } }">
                    {{ $t('Subscriptions') }}
                  </b-button>
                  <b-button variant="primary" size="sm" class="text-light mr-1 mt-1 mt-md-0"
                    :to="{ name: 'user-profile', query: { tab: 'profile' } }">
                    {{ $t('Profile') }}
                  </b-button>
                  <b-button variant="primary" size="sm" class="text-light mt-1 mt-md-0"
                    :to="{ name: 'user-affiliation-overview'}">
                    {{ $t('Invite') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card class="card-designs w-100 d-block d-md-none">
              <div class="row d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-between align-items-center">
                  <b-avatar size="48" class="avatar-border-2 box-shadow-1 d-block d-md-none mr-2" variant="primary"
                        :src="userAvatar" />
                    <b-card-title class="text-uppercase">
                      Hi, {{ userData.first_name }}
                    </b-card-title>
                </div>
                <!-- <div>
                  <b-card-header class="py-0 mt-1 pl-1">
                  </b-card-header>
                </div> -->
                <div class="mt-1">
                  <p class="text-white">{{ $t('Transform your network into net-worth! Earn juicy commissions when you invite friends and others to subscribe!') }}</p>
                </div>
              </div>
              <b-button variant="primary" size="sm" class="text-light mr-1 mt-1 mt-md-0"
                :to="{ name: 'user-profile', query: { tab: 'subscription' } }">
                {{ $t('Subscriptions') }}
              </b-button>
              <b-button variant="primary" size="sm" class="text-light mr-1 mt-1 mt-md-0"
                :to="{ name: 'user-profile', query: { tab: 'profile' } }">
                {{ $t('Profile') }}
              </b-button>
              <b-button variant="primary" size="sm" class="text-light mt-1 mt-md-0"
                :to="{ name: 'user-affiliation-overview' }">
                {{ $t('Invite') }}
              </b-button>
            </b-card>
          </b-col>
          <b-col cols="12" md="8">
            <b-row>
              <b-col cols="12" md="6" @click="$router.push({name: 'user-ai-chat'})" class="cp">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2 class="fs-md-1p5rem"><strong>{{ usageData.word_used }}<span class="text-muted">/{{ usageData.word_balance
                        }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <div class="text-uppercase text-light fs-md-1rem">
                          {{ $t('Words generated') }}
                        </div>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img fluid class="pr-1" :src="require('@/assets/images/app-icons/ai/chat-48.png')" />
                    </div>
                  </div>
                  <CustomProgressBarVue :max="usageData.word_balance" :value="usageData.word_used" />
                </b-card>
              </b-col>
              <b-col cols="12" md="6" @click="$router.push({name: 'user-ai-images'})" class="cp">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2 class="fs-md-1p5rem"><strong>{{ usageData.image_used }}<span class="text-muted">/{{ usageData.image_balance
                        }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <div class="text-uppercase text-light fs-md-1rem">
                          {{ $t('Images generated') }}
                        </div>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img fluid class="pr-1" :src="require('@/assets/images/app-icons/ai/image-48.png')" />
                    </div>
                  </div>
                  <CustomProgressBarVue :max="usageData.image_balance" :value="usageData.image_used" />
                </b-card>
              </b-col>
              <b-col cols="12" md="6" @click="$router.push({name: 'user-ai-t2s'})" class="cp">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2 class="fs-md-1p5rem"><strong>{{ usageData.t2s_used }}<span class="text-muted">/{{ usageData.t2s_balance
                        }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <div class="text-uppercase text-light fs-md-1rem">
                          {{ $t('Voiceover') }}
                        </div>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img fluid class="pr-1" :src="require('@/assets/images/app-icons/ai/speech-48.png')" />
                    </div>
                  </div>
                  <CustomProgressBarVue :max="usageData.t2s_balance" :value="usageData.t2s_used" />
                </b-card>
              </b-col>
              <b-col cols="12" md="6" @click="$router.push({name: 'user-ai-s2t'})" class="cp">
                <b-card class="card-designs">
                  <div class="row d-flex justify-content-between align-items-center">
                    <div>
                      <b-card-body class="py-0 pl-1">
                        <h2 class="fs-md-1p5rem"><strong>{{ usageData.s2t_used }}<span class="text-muted">/{{ usageData.s2t_balance
                        }}</span></strong></h2>
                      </b-card-body>
                      <b-card-header class="py-0 mt-1 pl-1">
                        <div class="text-uppercase text-light fs-md-1rem">
                          {{ $t('Transcribe') }}
                        </div>
                      </b-card-header>
                    </div>
                    <div>
                      <b-img fluid class="pr-1" :src="require('@/assets/images/app-icons/ai/voice-48.png')" />
                    </div>
                  </div>
                  <CustomProgressBarVue :max="usageData.s2t_balance" :value="usageData.s2t_used" />
                </b-card>
              </b-col>
              <b-col cols="12" md="6" class="d-none">
                <b-card class='mb-1'>
                  <div class="d-flex justify-content-start">
                    <div class="mr-2">
                      <b-avatar size="36" class="avatar-border-2 box-shadow-1" variant="primary"
                        :src="require('@/assets/images/app-icons/ai/order-48.png')" />
                    </div>
                    <div>
                      <h4><strong>{{ usageData.orders }}</strong></h4>
                      <p class="mb-0"><small class="text-muted">Total order created</small></p>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="12" md="6" class="d-none">
                <b-card class='mb-1'>
                  <div class="d-flex justify-content-start">
                    <div class="mr-2">
                      <b-avatar size="36" class="avatar-border-2 box-shadow-1" variant="primary"
                        :src="require('@/assets/images/app-icons/ai/booking-48.png')" />
                    </div>
                    <div>
                      <h4><strong>{{ usageData.bookings }}</strong></h4>
                      <p class="mb-0"><small class="text-muted">Total bookings created</small></p>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { isEmpty } from '@/utilities'
import { mapActions } from 'vuex'
import CustomProgressBarVue from '@/views/shared/common-ui/CustomProgressBar.vue'
export default {
  name: "UserDashboard",

  components: {
    CustomProgressBarVue
  },

  data: () => ({
    userAvatar: null,
    userData: {},
    usageData: {
      word_used: 0,
      ai_usages_templates: 0,
      image_used: 0,
      s2t_used: 0,
      t2s_used: 0,
      orders: 0,
      bookings: 0,
      word_balance: 0,
      image_balance: 0,
      s2t_balance: 0,
      t2s_balance: 0,
    }
  }),

  computed: {

  },

  methods: {
    ...mapActions('ai', [
      'getUsage'
    ]),
    inputFile(payload) {
      if (this.userData) this.userData.picture = payload
    },
  },

  created() {

  },

  mounted() {
    this.aiAvatar = require('@/assets/images/app-icons/ai/ai-48.png')
    let userData = localStorage.getItem('userData')
    if (!isEmpty(userData)) {
      this.userData = JSON.parse(userData)
      this.userAvatar = `${process.env.VUE_APP_API_BASE_END_POINT}/${this.userData.picture}`
    }
    this.getUsage().then(r => {
      this.usageData = r
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.mobile-actions {
  a {
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  .mobile-actions {
    a {
      font-size: 12px;
      padding: 0.786rem 0.5rem;
      margin: 0 auto !important;
      margin-bottom: 10px !important;
      width: 280px;
      display: block;
    }
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';

.fs-16px {
  font-size: 3rem
}
</style>
